<template>
  <div class="MaintenanceList">

    <item-placeholder-list v-if="loading" />
        <div :id="item.id" v-for="item in maintenanceList" :key="item.id" class=" d-flex">
          <img :src="require('@/assets/images/reorder-indicator.svg')" alt="" class="reorder-indicator"/>
          <maintenance-item
            :key="item.id"
            :maintenance-item="item"
            @delete="deleteItem"
          />
        </div>
    <div class="d-flex justify-content-center" v-if="totalPages > 1">
      <paginate
        :page-count="totalPages > 1"
        :prev-text="'‹'"
        :next-text="'›'"
        class="mr-5"
        :container-class="'pagination-container'"
        :page-class="'page-item'"
        :active-class="'page-active'"
        prev-link-class="page-arrow"
        next-link-class="page-arrow"
        v-model="page"
      />
      <monitor-items-per-page @updateItemsPerPage="pagination.take = $event"/>
    </div>
  </div>
</template>

<script>
import ItemPlaceholderList from '@/components/Common/ItemPlaceholderList.vue'
import MaintenanceItem from '@/components/StatusPage/Maintenance/MaintenanceItem.vue'
import MonitorItemsPerPage from '@/components/MonitorList/MonitorItemsPerPage.vue'
import statusPagesApi from '@/api/statusPagesApi'
import Paginate from 'vuejs-paginate'

export default {
  components: {
    MonitorItemsPerPage,
    MaintenanceItem,
    ItemPlaceholderList,
    // draggable,
    Paginate
  },

  props: {
    statusPage: {
      required: true
    }
  },

  data () {
    return {
      page: 1,
      pagination: {
        skip: 0,
        take: 20
      },
      total: 4,
      maintenanceList: [],
      mockData: [
        {
          id: 1,
          name: 'Switching from HDDs to SATA 1',
          status: 'live',
          date: '28/08/23 @ 2:05 PM - 28/08/23 @ 5:22 PM'
        },
        {
          id: 2,
          name: 'Switching from HDDs to SATA 2',
          status: 'scheduled',
          date: '18/08/23 @ 2:05 PM - 19/08/23 @ 1:12 AM'
        },
        {
          id: 3,
          name: 'Switching from HDDs to SATA 3',
          status: 'scheduled',
          date: '12/08/23 @ 2:05 PM - 12/08/23 @ 5:22 PM'
        },
        {
          id: 4,
          name: 'Enter a valid ISO 3166-1 alpha-2 code',
          status: 'past event',
          date: '08/08/23 @ 2:05 PM - 08/08/23 @ 5:22 PM'
        },
        {
          id: 5,
          name: 'Provided state not found',
          status: 'past event',
          date: '06/08/23 @ 2:05 PM - 28/08/23 @ 5:22 PM'
        },
        {
          id: 6,
          name: 'Incorrect email address format',
          status: 'past event',
          date: '28/08/23 @ 2:05 PM - 28/08/23 @ 5:22 PM'
        },
        {
          id: 7,
          name: 'Invalid ISO 3166-1 alpha-2 code',
          status: 'past event',
          date: '28/08/23 @ 2:05 PM - 28/08/23 @ 5:22 PM'
        },
        {
          id: 8,
          name: 'Invalid ISO 3166-1 alpha-2 code',
          status: 'past event',
          date: '28/08/23 @ 2:05 PM - 28/08/23 @ 5:22 PM'
        },
        {
          id: 9,
          name: 'Invalid ISO 3166-1 alpha-2 code',
          status: 'past event',
          date: '28/08/23 @ 2:05 PM - 28/08/23 @ 5:22 PM'
        },
        {
          id: 10,
          name: 'Invalid ISO 3166-1 alpha-2 code',
          status: 'past event',
          date: '28/08/23 @ 2:05 PM - 28/08/23 @ 5:22 PM'
        }
      ],
      loading: false
    }
  },

  created () {
    this.getMaintenanceList()
    this.total = this.maintenanceList.length
  },

  watch: {
    maintenancesParams: {
      handler: function () {
        this.getMaintenanceList(this.maintenancesParams)
      }
    },

    page () {
      this.pagination.skip = (this.page - 1) * this.pagination.take
    }
  },

  methods: {
    async getMaintenanceList (params) {
      this.loading = true
      this.maintenanceList = []

      const maintenancesResponse = await statusPagesApi.getStatusPageMaintenanceItems(this.statusPage.id, params)
      this.maintenanceList = maintenancesResponse.data

      this.total = maintenancesResponse.total
      this.loading = false
    },
    async deleteItem (event) {
      await statusPagesApi.deletePageMaintenanceItem(event)
      this.maintenanceList = this.maintenanceList.filter(item => item.id !== event)
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.total / this.pagination.take)
    },

    maintenancesParams () {
      return {
        ...this.pagination
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.MaintenanceList {
  display: flex;
  flex-direction: column;
  flex: 1 0;
  .reorder-indicator {
    padding-bottom: 16px;
    opacity: 0;
    cursor: move;
    position: relative;
    left: -25px;
    margin-right: -11px;
  }
  .sortable:hover > .reorder-indicator {
    opacity: 1;
  }
}

.pagination {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-bulk {
    width: 147px;
  }

  &-container {
    display: flex;
    justify-content: space-between;
    padding: 0;
    list-style: none;

    ::v-deep {
      .page-active {
        background-color: rgba(0,0,0,0.1) !important;
        cursor: default;
        pointer-events: none;

        a {
          color: #4F4F4F;
        }
      }

      .page-arrow {
        color: #333333;
        opacity: 0.5;
        font-size: 20px;
      }

      li {
        margin: 0 5px;
        border-radius: 50% !important;
        cursor: pointer;

        &:hover {
          background-color:rgba(0,0,0,0.1) !important;
        }
      }

      li a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 34px;
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        color: #333;

      }
    }
  }
}

</style>
