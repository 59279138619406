<template>
  <div class="FiltersLocationNode">
    <base-checkbox
      v-model="selected"
      @input="$emit('toggle', selected)"
    >
      <node-name
                 :node="node"
                 :inline="true" />
    </base-checkbox>
  </div>
</template>

<script>
import NodeName from '@/components/Node/NodeName.vue'

export default {
  components: {
    NodeName
  },

  props: {
    node: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      selected: this.node.selected
    }
  },

  watch: {
    node: {
      handler: function (newValue) {
        this.selected = newValue.selected
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.FiltersLocationNode {
  display: flex;
  align-items: center;
  margin-bottom: 14px;

  .base-checkbox {
    margin: 0;
  }
}
</style>
