<template>
  <div class="MaintenanceItem">
    <div class="container-fluid">
      <div class="row justify-content-between align-items-center">
        <div class="col col-6">
          <span class="pl-1 font-weight-5 protect-long-text" :class="{
                'item-title-inactive': maintenanceItem.status === 'past event',
               }">
            <router-link :to="editRoute" class="font-weight-5">{{ maintenanceItem.name || 'Untitled' }}</router-link>
          </span>
        </div>

          <div class="col">
            <div class="row align-items-center">
              <div class="col col-2" :class="{
                'status-badge-active': maintenanceItem.status === 'scheduled' || maintenanceItem.status === 'live',
                'status-badge-inactive': maintenanceItem.status === 'past_event',
               }">
                <span class="badge-text">
                  {{ maintenanceItem.status.replace('_', ' ') }}
                </span>
              </div>
              <div class="col col-8 mr-1 font-weight-5 protect-long-text date">
                {{ maintenanceItem.date }}
              </div>
              <div v-if="user.role !== role.Viewer && user.role !== role.Accountant" class="col">
                <maintenance-actions
                  :maintenanceItem="maintenanceItem"
                  @delete="$emit('delete', maintenanceItem.id)" />
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Role } from '@/_helpers/role.js'
import MaintenanceActions from '@/components/StatusPage/Maintenance/MaintenanceActions.vue'

export default {
  components: { MaintenanceActions },
  props: {
    maintenanceItem: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      role: Role
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ]),

    editRoute () {
      return {
        name: 'statusPages.single.maintenance.single',
        params: {
          maintenanceId: this.maintenanceItem.id
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.MaintenanceItem {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 70px;
  box-shadow: 0 2px 4px rgba(25,34,29,0.05);
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 16px;
  transition: box-shadow 200ms ease 0s, border-color 200ms ease 0s;

  .protect-long-text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .item-title-inactive{
    opacity: 0.6;
  }
  .status {
    @media (max-width: 990px) {
      display: none !important;
    }

    &-mobile {
      display: none !important;
      @media (max-width: 990px) {
        display: flex !important;
      }
    }
  }
  .date {
    opacity: 0.6;
  }

  .status-badge-active {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 100px;
    background-color: #1aaf5d;
    border-radius: 10px;
  }

  .status-badge-inactive {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 100px;
    background-color: darkgrey;
    border-radius: 10px;
  }

  .badge-text {
    display: flex;
    justify-content: center;
    min-width: 75px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
  }
}
</style>
