<template>
  <div class="MonitorViewOptions">
    <div ref="link">
      <base-button
        class="button"
        icon="IconButtonArrowDownGrey"
        :icon-width="12"
        :icon-height="7"
      >
        {{selectedItem}}
      </base-button>
    </div>

    <div ref="dropdown" class="dropdown px-0">
      <div class="title mt-2 ml-1 mb-1">Per page</div>
      <node v-for="item in itemsPerPageOptions"
            :key="item.id"
            :node="item"
            class="view-options-dropdown mb-0"
            @toggle="selectOption(item, $event)"
      />
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import Node from '@/components/Common/Filters/FilterLocation/FilterLocationNode.vue'
import BaseButton from '@/components/Base/BaseButton.vue'

export default {
  components: {
    BaseButton,
    Node
  },

  props: {
    perPage: null
  },

  data () {
    return {
      tippyInstance: null,
      open: false,
      selectedItem: null,
      itemsPerPageOptions: [
        { id: 1, title: '20', value: 20, selected: false },
        { id: 2, title: '40', value: 40, selected: false },
        { id: 3, title: '60', value: 60, selected: false },
        { id: 4, title: '80', value: 80, selected: false },
        { id: 5, title: '100', value: 100, selected: false }
      ]
    }
  },

  mounted () {
    this.setTippy()
    this.setSelectedOption()
  },

  methods: {
    selectOption (option, selected) {
      this.itemsPerPageOptions = this.itemsPerPageOptions.map((item) => ({
        ...item,
        selected: item.id === option.id ? selected : false
      }))

      this.selectedItem = option.title
      this.tippyInstance.hide()
      this.$emit('updateItemsPerPage', option.value)
    },

    setSelectedOption () {
      if (this.perPage) {
        this.selectedItem = this.itemsPerPageOptions.find(item => {
          if (item.value === this.perPage) {
            item.selected = true
            return item
          }
        }).title
      } else {
        this.selectedItem = this.itemsPerPageOptions[0].title
      }
    },

    setTippy () {
      this.tippyInstance = tippy(this.$refs.link, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'top-end',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none',

        onShow: () => {
          this.open = true
        },

        onHide: () => {
          this.open = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.MonitorViewOptions {
  display: flex;
  align-items: center;
  padding: 15px 0;

  .button {
    display: flex;
    align-items: center;
    height: 34px;
    padding: 0 12px;
    background-color: rgba(0,0,0,0.1) !important;
    color: #333333;
    border: 1px solid #E9EBEC;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.005em;

    ::v-deep {
      .truncate {
        flex-direction: row-reverse;

        svg {
          margin: 0 0 0 8px !important;
        }
      }
    }

    &:hover {
      color: #333333;
    }
  }

  .view-options-dropdown {
    padding: 9px 7px;

    ::v-deep {
      .flag {
        display: none;
      }

      .title {
        margin-left: 0;
        font-size: 15px;
        font-weight: 500;
      }

      .ip {
        display: none;
      }

      .base-checkbox {
        width: 100%;

        & .checkmark {
          border: none;
          background-color: transparent;
        }
      }

      .checkmark {
        &:after {
          padding: 2px 3px;
          color: map-get($colors, purple-1);
        }
      }
    }

    &:hover {
      width: 100%;
      background-color: map-get($colors, purple-1);
      color: white;
      border-radius: 4px;

      ::v-deep {
        .checkmark {
          &:after {
            padding: 2px 3px;
            color: white;
          }
        }
      }
    }
  }

  .dropdown {
    width: 80px;

    .title {
      margin-bottom: 14px;
      font-size: 12px;
      font-weight: 700;
      color: #BDBDBD;
      text-transform: uppercase;
    }
  }
}
</style>
