<template>
  <div class="monitors" v-if="user.subscription_plan.schedule_maintenance">
    <h2 class="ml-2">Maintenance</h2>

    <div class="card mb-3">
      <div class="card-body d-flex">
        <div class="mr-3">
          Maintenance allows you to pause monitors for a certain period of time to prevent them from impacting uptime
          statistics.
          Additionally, you can display incident notes on your status pages and provide updates to your users.
        </div>
        <router-link :to="createRoute">
          <base-button color="primary"
                       class="addNew"
                       :icon="'IconAdd'"
          >
            Schedule maintenance
          </base-button>
        </router-link>
      </div>
    </div>

    <maintenance-list :status-page="statusPage"/>
  </div>
  <div v-else>
    <div class="card mb-3">
      <div class="card-body">
        <div class="mr-3">
          <div class="upgrade monitors">
            <UpgradeBadge text="Schedule Maintenance is available only for paid customers."/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaintenanceList from '@/components/StatusPage/Maintenance/MaintenanceList.vue'
import BaseButton from '@/components/Base/BaseButton.vue'
import UpgradeBadge from '@/components/Common/UpgradeBadge.vue'

export default {
  components: { UpgradeBadge, BaseButton, MaintenanceList },

  metaInfo: {
    title: 'Maintenance'
  },

  props: {
    statusPage: {
      required: true
    }
  },

  computed: {
    createRoute () {
      return {
        name: 'statusPages.single.maintenance.create'
      }
    },
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
.upgrade {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  @media (max-width: 767px) {
    margin: 24px 0;
    min-height: unset !important;
  }
}
</style>
